<template>
    <Layout :show-admin="showAdmin" :show-super-admin="showSuperAdmin" :show-super-sales="showSuperSales">
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="row mt-3 flex-xs-column-reverse">
                    <div class="col-sm-12 col-md-6 mt-2">
                        <div id="tickets-table_filter" class="dataTables_filter">
                            <label class="d-inline-flex align-items-center fw-normal">
                                Recherche
                                <b-form-input v-model="filter" type="search" class="form-control form-control-sm ms-2"></b-form-input>
                            </label>
                            <div class="form-check form-switch mb-3 mt-3" @click="toggleSwitch()">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="show-prospects"
                                    :checked="showProspect"
                                />
                                <label class="form-check-label" for="show-prospects">Afficher les prospects</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div class="text-end">
                            <b-button class="me-2" variant="outline-dark" @click="clearSelection()" v-if="customersList.length > 0">
                                Réinitialiser
                                <i class="uil uil-times ms-2"></i>
                            </b-button>
                            <b-button class="me-2" variant="secondary" @click="fusionCustomers()" :class="{'disabled': customersList.length < 2 }">
                                Fusionner les clients
                                <i class="uil uil-arrow-compress-h ms-2"></i>
                            </b-button>
                            <b-button variant="primary" v-b-modal.modal-add>
                                Créer une fiche client
                                <i class="uil uil-plus ms-2"></i>
                            </b-button>
                            <b-modal id="modal-add" title="Créer une fiche client" hide-footer size="lg" centered>
                                <AddCustomer @customers-updated="onCustomersUpdated" />
                            </b-modal>
                        </div>
                    </div>
                </div>
                <div class="table-responsive mb-0">
                    <b-table
                        table-class="table table-centered datatable table-card-list"
                        thead-tr-class="bg-transparent"
                        :items="customersData"
                        :fields="fields"
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                    >
                        <template v-slot:cell(check)="data">
                            <div class="custom-control custom-checkbox text-center">
                                <input type="checkbox" class="custom-control-input" :ref="`customercheck${data.item.id}`" :id="`customercheck${data.item.id}`" @click="toggleFromCustomersList(data.item.id)" />
                                <label class="custom-control-label" :for="`customercheck${data.item.id}`"></label>
                            </div>
                        </template>

                        <template v-slot:cell(lastname)="data">
                            <div class="avatar-xs d-inline-block me-2">
                                <span class="avatar-title rounded-circle bg-light text-body">{{ data.item.firstname.charAt(0) }}{{ data.item.lastname.charAt(0) }}</span>
                            </div>

                            {{ data.item.firstname }} {{ data.item.lastname }}

                            <i class="bx bxs-star me-2" v-if="data.item.flag"></i>
                        </template>
                        <template v-slot:cell(status)="data">
                            <div 
                                class="badge bg-pill font-size-12"
                                :class="[`bg-${CustomerService.getStatusColor(data.item.status)}`]"
                            >{{ data.item.status }}</div>
                        </template>
                        <template v-slot:cell(action)="data">
                            <ul class="list-inline mb-0">
                                <li class="list-inline-item">
                                    <router-link :to="'/customer/' + data.item.id" class="px-2 text-primary" content="Modifier" v-tippy>
                                        <i class="uil uil-pen font-size-18"></i>
                                    </router-link>
                                </li>
                                <li class="list-inline-item">
                                    <a href="javascript:void(0);" class="px-2 text-danger" content="Supprimer" v-tippy @click="onDeleteCustomer(data.item.id)">
                                        <i class="uil uil-trash-alt font-size-18"></i>
                                    </a>
                                </li>
                            </ul>
                        </template>
                    </b-table>
                    <div class="text-center mb-5" v-if="!loading && rows === 0">
                        Il n'y a rien à afficher pour le moment. <a href="#" v-b-modal.modal-add>Créer un client</a> pour commencer à avoir des données.
                    </div>
                    <div class="text-center" v-if="loading">
                        <b-spinner class="align-middle" variant="primary"></b-spinner>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_length" class="dataTables_length">
                            <label class="d-inline-flex align-items-center fw-normal">
                                <span class="pe-2">Afficher</span>
                                <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
                                <span class="ps-2">entrées</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div class="dataTables_paginate paging_simple_numbers float-end">
                            <ul class="pagination pagination-rounded">
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Swal from "sweetalert2";
    import Layout from "@/views/layouts/main";
    import PageHeader from "@/components/page-header";
    import AddCustomer from "@/forms/add-customer";
    import appConfig from "@/app.config";
    import api from '@/api/api';
    import Utilities from "@/services/utilities";
    import CustomerService from "@/services/customer.service";

    /**
     * Customer component
     */
    export default {
        components: { Layout, PageHeader, AddCustomer },
        page: {
            title: "Clients",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        props: ["showAdmin", "showSuperAdmin", "showSuperSales"],
        data() {
            return {
                CustomerService,
                title: "Clients",
                items: [{
                        text: "Accueil",
                        to: "/"
                    },
                    {
                        text: "Clients",
                        active: true,
                    },
                ],
                customersData: [],
                totalRows: 1,
                currentPage: 1,
                perPage: 25,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "lastname",
                sortDesc: false,
                loading: true,
                customersList: [],
                showProspect: false,
                fields: [
                    {
                        key: "check",
                        label: "",
                    },
                    {
                        key: "lastname",
                        label: "Nom",
                        sortable: true,
                    },
                    {
                        key: "created_date",
                        label: "Date de création",
                        sortable: false,
                    },
                    {
                        key: "status",
                        label: "Status",
                        sortable: true
                    },
                    {
                        key: "postcode",
                        label: "Code postal",
                        sortable: true,
                    },
                    {
                        key: "phone",
                        label: "Téléphone",
                        sortable: true,
                    },
                    "action",
                ]
            };
        },
        computed: {
            rows() {
                return this.customersData.length;
            },
        },
        mounted() {
            if (!this.$props.showAdmin) {
                this.$router.push('/');
            }
            this.totalRows = this.items.length;
            this.loadCustomers();
        },
        methods: {
            toggleFromCustomersList(id) {
                var index = this.customersList.indexOf(id);
                if (index !== -1) {
                    this.customersList.splice(index, 1);
                } else {
                    this.customersList.push(id);
                }
            },
            clearSelection() {
                for (let i = 0; i < this.customersList.length; i++) {
                    this.$refs['customercheck' + this.customersList[i]].checked = false;
                }
                this.customersList = [];
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            closeModal() {
                this.$bvModal.hide('modal-add');
            },
            onCustomersUpdated() {
                this.closeModal();
                this.loadCustomers();
            },
            fusionCustomers() {
                if (this.customersList < 2) {
                    Utilities.alertmsg('Veuillez sélectionner au moins 2 clients');
                    return;
                }

                Swal.fire({
                    title: "Attention",
                    text: "Êtes-vous sûr de vouloir fusionner ces clients ? Tous les clients à l'exception du client favori seront supprimés. Cette action est irréversible.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Fusionner",
                    cancelButtonText: "Annuler",
                }).then((result) => {
                    if (result.isConfirmed) {
                        api
                            .post('/fusion-customers', {
                                ids: this.customersList.join(',')
                            })
                            .then(
                                (response) => {
                                    if (response && response.status == 200) {
                                        Utilities.successmsg(response.data);
                                        this.clearSelection();
                                        this.loadCustomers();
                                    }
                                },
                                (error) => {
                                    Utilities.alertmsg(error.response.data.detail);
                                }
                            )
                    }
                });
            },
            toggleSwitch() {
                this.showProspect = !this.showProspect;
                this.loadCustomers();
            },
            loadCustomers() {
                this.loading = true;
                this.customersData = [];

                var params = {};
                if (this.showProspect) {
                    params = {
                        params: {
                            type: 'prospect'
                        }
                    }
                }
                api
                    .get('/customers', params)
                    .then(
                        (response) => {
                            if (response && response.status == 200) {
                                if (Array.isArray(response.data)) {
                                    this.customersData = response.data;
                                    this.loading = false;
                                }
                            }
                        },
                        () => {
                            Utilities.errormsg();
                        }
                    )
            },
            onDeleteCustomer(id) {
                Swal.fire({
                    title: "Attention",
                    text: "Êtes-vous sûr de vouloir supprimer ce client ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Supprimer",
                    cancelButtonText: "Annuler",
                }).then((result) => {
                    if (result.value) {
                    api
                        .delete('/customers/' + id)
                        .then(
                            (response) => {
                                if (response && response.status == 204) {
                                    Swal.fire({
                                    title: "Succès",
                                    text: "Le client a bien été supprimé !",
                                    timer: 1500,
                                    icon: "success",
                                    confirmButtonColor: "#2e58a6",
                                    confirmButtonText: "Fermer",
                                    });
                                    this.loadCustomers();
                                }
                            },
                            (error) => {
                                if (error.response.data.detail) {
                                    Utilities.alertmsg(error.response.data.detail);
                                    return;
                                }

                                Utilities.errormsg();
                            }
                        )
                    }
                });
            }
        },
        middleware: "authentication",
    };
</script>

<style lang="scss" scoped>
    @media all and (max-width: 576px) {
        .dataTables_filter::v-deep {
            label, input {
                width: 100%;
            }

            label {
                margin-top: 30px;
            }
        }

        .flex-xs-column-reverse {
            flex-direction: column-reverse;
        }
    }

    ::v-deep {
      th, td {
        white-space: nowrap;
      }
    }

    .bxs-star {
        color: #e5c100;
    }

    ::v-deep .table.b-table > thead > tr {
        & >[aria-sort] {
            cursor: pointer;
            background-image: none;
            background-repeat: no-repeat;
            background-size: 0.65em 1em;

            &:not(.b-table-sort-icon-left) {
                background-position: right 0.375rem center;
                padding-right: calc(0.75rem + 0.65em);
            }
        }

        & > [aria-sort=descending] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E");
        }

        & > [aria-sort=ascending] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E");
        }

        & > [aria-sort=none] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath opacity='.3' d='M51 1l25 23 24 22H1l25-22zm0 100l25-23 24-22H1l25 22z'/%3E%3C/svg%3E");
        }
    }
</style>